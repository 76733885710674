<template>
    <div :class="'grid grid-cols-1 ' + gridGaps + ' sm:grid-cols-6 md:col-span-2'">
        <BaseInput
            v-if="customAddress"
            v-model="data.country"
            :label="$t('address.country')"
            :name="$t('address.country') + inputIdentifier"
            class="sm:col-span-6"
            :rules="(required) ? 'required' : ''"
            :required="required">
            <template #inputHelper>
                <div class="text-sm">
                    <p
                        class="text-gray-400 hover:text-gray-400 cursor-pointer mb-1"
                        @click="toggleCustomAddress()">
                        {{ $t('address.searchAddress') }}
                    </p>
                </div>
            </template>
            <BaseSearchSelect
                v-model="data.country"
                :options="availableCountries"
                display-key="name"
                identifier="code"
                :required="required"
                @update:model-value="onCountryChange()">
                <template #option="{name}">
                    {{ name }}
                </template>
            </BaseSearchSelect>
        </BaseInput>

        <BaseInput
            v-if="!customAddress"
            v-model="data.street"
            :label="$t('address.address')"
            :name="$t('address.address') + inputIdentifier"
            class="md:col-span-4 sm:col-span-6"
            :rules="(required) ? 'required' : ''"
            :required="required">
            <template #inputHelper>
                <div class="text-sm">
                    <p
                        class="text-gray-400 hover:text-gray-400 cursor-pointer mb-1"
                        @click="toggleCustomAddress()">
                        {{ $t('address.customAddress') }}
                    </p>
                </div>
            </template>
            <Combobox
                v-model="selectedOption"
                :required="required"
                :nullable="nullable"
                as="div">
                <!--                <div class="flex items-center justify-between">-->
                <!--                    <ComboboxLabel class="block text-sm font-medium leading-5 text-gray-900 dark:text-gray-300 font-semibold mb-1">-->
                <!--                        {{ (label) ? label : $t('address.address') }}<span v-if="required">*</span>-->
                <!--                    </ComboboxLabel>-->
                <!--                </div>-->

                <div class="relative">
                    <ComboboxInput
                        class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 dark:text-gray-300 shadow-sm ring-1 ring-inset ring-gray-300
                                focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6
                                after:content-[attr(placeholder)] after:absolute"
                        :placeholder="(searchPlaceholder) ? searchPlaceholder : $t('address.searchPlaceholder')"
                        :display-value="(option) => (option) ? option.zipcode + ' ' + option.city + ' ' + option.street : ''"
                        @change="query = $event.target.value; onSearch()"
                        @blur="query = ''" />

                    <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <Icon
                            name="bi:chevron-expand"
                            class="h-5 w-5 text-gray-400"
                            aria-hidden="true" />
                    </ComboboxButton>

                    <ComboboxOptions
                        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ComboboxOption
                            selected
                            disabled>
                            <li
                                :class="'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 dark:text-gray-300'">
                                <span :class="['block', {'truncate' : truncate}]">
                                    {{ (searchPlaceholder) ? searchPlaceholder : $t('address.searchPlaceholder') }}
                                </span>
                            </li>
                        </ComboboxOption>
                        <ComboboxOption
                            v-if="loading"
                            disabled>
                            <li
                                :class="'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 dark:text-gray-300'">
                                <LoadingSpinner />
                            </li>
                        </ComboboxOption>
                        <ComboboxOption
                            v-if="noResults && !loading"
                            disabled>
                            <li
                                :class="'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 dark:text-gray-300 cursor-pointer'"
                                @click="toggleCustomAddress()">
                                <span :class="['block', {'truncate' : truncate}]">
                                    {{ $t('address.noSearchResults') }}
                                </span>
                            </li>
                        </ComboboxOption>
                        <ComboboxOption
                            v-for="(option, index) in options"
                            :key="index"
                            v-slot="{ active, selected }"
                            :value="option"
                            as="template">
                            <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-primary-500 text-white' : 'text-gray-900 dark:text-gray-300']">
                                <span :class="['block', {'truncate' : truncate}]">
                                    <slot v-bind="option">
                                        {{ option.zipcode + ' ' + option.city + ' ' + option.street }}
                                    </slot>
                                </span>

                                <span
                                    v-if="selected"
                                    :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-primary-500']" />
                            </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                </div>
            </Combobox>
        </BaseInput>
        
        <BaseInput
            v-if="customAddress"
            v-model="data.street"
            :label="$t('address.street')"
            :name="$t('address.street') + inputIdentifier"
            :placeholder="$t('address.street')"
            class="md:col-span-4 sm:col-span-6"
            :rules=" (required) ? 'required|max:255' : 'max:255'"
            :required="required"
            auto-complete="given-name" />

        <BaseInput
            v-model="data.house_number"
            :label="$t('address.houseNumber')"
            :name="$t('address.houseNumber') + inputIdentifier"
            :placeholder="$t('address.houseNumber')"
            class="md:col-span-2 sm:col-span-6"
            :rules=" (required) ? 'required|max:20' : 'max:20'"
            :required="required" />

        <BaseInput
            v-if="customAddress && data.country !== 'DE'"
            v-model="data.zipcode"
            :label="$t('address.zipcode')"
            :name="$t('address.zipcode') + inputIdentifier"
            :placeholder="$t('address.zipcode')"
            class="sm:col-span-2"
            :rules=" (required) ? 'required|max:20' : 'max:20'"
            :required="required" />

        <BaseInput
            v-if="customAddress && data.country === 'DE'"
            v-model="data.zipcode"
            :label="$t('address.zipcode')"
            :name="$t('address.zipcode') + 'search' + inputIdentifier"
            class="sm:col-span-2"
            :rules="(required) ? 'required' : ''"
            :required="required">
            <Combobox
                v-model="selectedOptionZipcode"
                :required="required"
                :nullable="nullable"
                as="div">
                <!--                <ComboboxLabel class="block text-sm font-medium leading-5 text-gray-900 dark:text-gray-300 font-semibold mb-1">-->
                <!--                    {{ (label) ? label : $t('address.zipcode') }}<span v-if="required">*</span>-->
                <!--                </ComboboxLabel>-->

                <div class="relative">
                    <ComboboxInput
                        class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 dark:text-gray-300 shadow-sm ring-1 ring-inset ring-gray-300
                                focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6
                                after:content-[attr(placeholder)] after:absolute"
                        :placeholder="$t('address.searchZipcode')"
                        :display-value="(option) => (option) ? option.zipcode : ''"
                        @change="queryZipcode = $event.target.value; onSearchZipcode()"
                        @blur="queryZipcode = ''" />

                    <ComboboxButton
                        ref="comboboxZipcode"
                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <Icon
                            name="bi:chevron-expand"
                            class="h-5 w-5 text-gray-400"
                            aria-hidden="true" />
                    </ComboboxButton>

                    <ComboboxOptions
                        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ComboboxOption
                            selected
                            disabled>
                            <li
                                :class="'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 dark:text-gray-300'">
                                <span :class="['block', {'truncate' : truncate}]">
                                    {{ $t('address.searchZipcode') }}
                                </span>
                            </li>
                        </ComboboxOption>
                        <ComboboxOption
                            v-if="loading"
                            disabled>
                            <li
                                :class="'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 dark:text-gray-300'">
                                <LoadingSpinner />
                            </li>
                        </ComboboxOption>
                        <ComboboxOption
                            v-for="(option, index) in optionsZipcode"
                            :key="index"
                            v-slot="{ active, selected }"
                            :value="option"
                            as="template">
                            <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-primary-500 text-white' : 'text-gray-900 dark:text-gray-300']">
                                <span :class="['block', {'truncate' : truncate}]">
                                    <slot v-bind="option">
                                        {{ option.zipcode }}
                                    </slot>
                                </span>

                                <span
                                    v-if="selected"
                                    :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-primary-500']" />
                            </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                </div>
            </Combobox>
        </BaseInput>

        <BaseInput
            v-if="customAddress && data.country !== 'DE'"
            v-model="data.city"
            :label="$t('address.city')"
            :name="$t('address.city') + inputIdentifier"
            :placeholder="$t('address.city')"
            class="md:col-span-4 sm:col-span-6"
            :rules=" (required) ? 'required|max:100' : 'max:100'"
            :required="required" />

        <BaseInput
            v-if="customAddress && data.country === 'DE'"
            v-model="data.city"
            :label="$t('address.city')"
            :name="$t('address.city') + 'search' + inputIdentifier"
            class="md:col-span-4 sm:col-span-6"
            :rules="(required) ? 'required' : ''"
            :required="required">
            <Combobox
                v-model="selectedOptionCity"
                :required="required"
                :nullable="nullable"
                as="div">
                <!--                <ComboboxLabel class="block text-sm font-medium leading-5 text-gray-900 dark:text-gray-300 font-semibold mb-1">-->
                <!--                    {{ (label) ? label : $t('address.city') }}<span v-if="required">*</span>-->
                <!--                </ComboboxLabel>-->

                <div class="relative">
                    <ComboboxInput
                        class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 dark:text-gray-300 shadow-sm ring-1 ring-inset ring-gray-300
                                focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6
                                after:content-[attr(placeholder)] after:absolute"
                        :placeholder="$t('address.searchCity')"
                        :display-value="(option) => (option) ? option.city : ''"
                        @change="queryCity = $event.target.value; onSearchCity()"
                        @blur="queryCity = ''" />

                    <ComboboxButton
                        ref="comboboxCity"
                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <Icon
                            name="bi:chevron-expand"
                            class="h-5 w-5 text-gray-400"
                            aria-hidden="true" />
                    </ComboboxButton>

                    <ComboboxOptions
                        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ComboboxOption
                            selected
                            disabled>
                            <li
                                :class="'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 dark:text-gray-300'">
                                <span :class="['block', {'truncate' : truncate}]">
                                    {{ $t('address.searchCity') }}
                                </span>
                            </li>
                        </ComboboxOption>
                        <ComboboxOption
                            v-if="loading"
                            disabled>
                            <li
                                :class="'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 dark:text-gray-300'">
                                <LoadingSpinner />
                            </li>
                        </ComboboxOption>
                        <ComboboxOption
                            v-for="(option, index) in optionsCity"
                            :key="index"
                            v-slot="{ active, selected }"
                            :value="option"
                            as="template">
                            <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-primary-500 text-white' : 'text-gray-900 dark:text-gray-300']">
                                <span :class="['block', {'truncate' : truncate}]">
                                    <slot v-bind="option">
                                        {{ option.city }}
                                    </slot>
                                </span>

                                <span
                                    v-if="selected"
                                    :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-primary-500']" />
                            </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                </div>
            </Combobox>
        </BaseInput>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import {useI18n} from 'vue-i18n'
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from '@headlessui/vue'
import {$larafetch} from "@/utils/$larafetch";
import {useNuxtApp} from "nuxt/app";
import debounce from "lodash/debounce"
import {useStatic} from "@/store/static";

const {t: $t} = useI18n()
const staticStore = useStatic()

const props = defineProps({
    modelValue: {
        required: true,
        type: Object,
        description: 'The model holding the address'
    },
    withAgs: {
        required: false,
        type: Boolean,
        default: false,
        description: 'If the ags should be included in the address'
    },
    label: {
        required: false,
        type: [String, null],
        default:null,
        description: 'The label of the select'
    },
    searchPlaceholder: {
        required: false,
        type: [String, null],
        default: null,
        description: 'The placeholder text of the search input'
    },
    searchThreshold: {
        required: false,
        type: Number,
        default: 3,
        description: 'The threshold of the search input'
    },
    truncate: {
        required: false,
        type: Boolean,
        default: false,
        description: 'Truncate the text of the options if they are too long'
    },
    required: {
        required: false,
        type: Boolean,
        default: false,
        description: 'If the input is required'
    },
    gridGaps: {
        required: false,
        type: String,
        default: 'gap-x-6 gap-y-8',
        description: 'The grid gaps of the input'
    },
    inputIdentifierProp: {
        required: false,
        type: String,
        default: '',
        description: 'The property of data which should identify the input'
    },
    nullable: {
        required: false,
        type: Boolean,
        default: false,
        description: 'If the input is nullable'
    }
})

const emit = defineEmits(['update:modelValue'])

const data = computed({
    get: () => {
        if (props.modelValue) {
            return props.modelValue
        } else {
            return {
                country: 'DE',
                city: '',
                zipcode: '',
                street: '',
                house_number: '',
            }
        }
    },
    set: (value) => {
        emit('update:modelValue', value)
    }
})

const inputIdentifier = computed(() => {
    if (props.inputIdentifierProp !== '') {
        return data.value[props.inputIdentifierProp]
    }
    return ''
})

const query = ref('')
const queryCity = ref('')
const queryZipcode = ref('')
const options = ref([])
const optionsCity = ref([])
const optionsZipcode = ref([])
const selectedOption = ref(null)
const selectedOptionCity = ref(null)
const selectedOptionZipcode = ref(null)
const comboboxCity = ref(null)
const comboboxZipcode = ref(null)
const noResults = ref(false)
const loading = ref(false)
const initializing = ref(true)
const availableCountries = computed(() => staticStore.countries)
const customAddress = ref(false)

const onSearch = debounce(() => {
    if (query.value.length > props.searchThreshold) {
        fetchOptions(query.value)
    }
}, 350)

const onSearchCity = debounce(() => {
    const query = queryCity.value + (data.value.zipcode ? ' ' + data.value.zipcode : '')
    if (query.length > props.searchThreshold) {
        fetchOptions(query, 'city')
    }
}, 350)

const onSearchZipcode = debounce(() => {
    const query = queryZipcode.value + (data.value.city ? ' ' + data.value.city : '')
    if (query.length > props.searchThreshold) {
        fetchOptions(query, 'zipcode')
    }
}, 350)

onMounted(() => {
    if (data.value.country !== 'DE') {
        customAddress.value = true
    }
    if (data.value.country === 'DE') {
        selectedOption.value = {
            zipcode: props.modelValue.zipcode,
            city: props.modelValue.city,
            street: props.modelValue.street
        }
    }
    if (props.withAgs && !customAddress.value) {
        selectedOption.value.ags = props.modelValue.ags
    }
})

watch(() => selectedOption.value, (value) => {
    if (value) {
        data.value.zipcode = value.zipcode
        data.value.city = value.city
        data.value.street = value.street
        if (props.withAgs) {
            data.value.ags = value.ags
        }
    } else {
        data.value.zipcode = null
        data.value.city = null
        data.value.street = null
        if (props.withAgs) {
            data.value.ags = null
        }
    }
})

watch(() => selectedOptionCity.value, (value) => {
    if (value) {
        data.value.city = value.city
        if (props.withAgs && data.value.zipcode) {
            data.value.ags = value.ags
        }
        if (!data.value.zipcode && comboboxZipcode.value) {
            comboboxZipcode.value.$el.click()
            onSearchZipcode()
        }
    } else {
        data.value.city = null
    }
})

watch(() => selectedOptionZipcode.value, (value) => {
    if (value) {
        data.value.zipcode = value.zipcode
        if (props.withAgs && data.value.city) {
            data.value.ags = value.ags
        }
        if (!data.value.city && comboboxCity.value) {
            comboboxCity.value.$el.click()
            onSearchCity()
        }
    } else {
        data.value.zipcode = null
    }
})

function fetchOptions(search, mode = 'default') {
    loading.value = true
    $larafetch(useNuxtApp().$apiRoute('spa.geodata.index'), {
        method: 'GET',
        params: {
            withAgs: !!(props.withAgs),
            mode: mode,
            keyed: (mode !== 'default') ? mode : 'street',
            output: 'select-address',
            search: search
        }
    }).then(response => {
        switch (mode) {
        case 'zipcode':
            optionsZipcode.value = Object.values(response)
            break;
        case 'city':
            optionsCity.value = Object.values(response)
            break;
        default:
            options.value = Object.values(response)
            options.value.length === 0 ? noResults.value = true : noResults.value = false
            break;
        }
        loading.value = false
    }).catch(error => {
        loading.value = false
        if (error && error.response && error.response.status === 422) {
            notification.error(app.$i18n.t('invalidRequestData'))
        } else if (error && error.response && error.response.status === 404) {
            notification.error(app.$i18n.t('modelDoesNotExist'))
        }
        throw error
    });
}

function toggleCustomAddress () {
    customAddress.value = !customAddress.value
    data.value.house_number = null
    data.value.street = null
    data.value.city = null
    data.value.zipcode = null
    if (props.withAgs) {
        data.value.ags = null
    }
    selectedOption.value = null
    selectedOptionCity.value = null
    selectedOptionZipcode.value = null
    if(!customAddress.value) {
        data.value.country = 'DE'
    }
}

function onCountryChange() {
    // ignore changes on component initialization
    if (!initializing.value) {
        data.value.zipcode = null
        data.value.city = null
        data.value.street = null
        data.value.house_number = null
        if (props.withAgs) {
            data.value.ags = null
        }
    }
    initializing.value = false
}
</script>

<style scoped>

</style>